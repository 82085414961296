@font-face {
  font-family: 'Archia';
  src: url('fonts/Archia/archia-thin-webfont.eot');
  src:
    url('fonts/Archia/archia-thin-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/Archia/archia-thin-webfont.woff2') format('woff2'),
    url('fonts/Archia/archia-thin-webfont.woff') format('woff'),
    url('fonts/Archia/archia-thin-webfont.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Archia';
  src: url('fonts/Archia/archia-light-webfont.eot');
  src:
    url('fonts/Archia/archia-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/Archia/archia-light-webfont.woff2') format('woff2'),
    url('fonts/Archia/archia-light-webfont.woff') format('woff'),
    url('fonts/Archia/archia-light-webfont.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Archia';
  src: url('fonts/Archia/archia-regular-webfont.eot');
  src:
    url('fonts/Archia/archia-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/Archia/archia-regular-webfont.woff2') format('woff2'),
    url('fonts/Archia/archia-regular-webfont.woff') format('woff'),
    url('fonts/Archia/archia-regular-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Archia';
  src: url('fonts/Archia/archia-medium-webfont.eot');
  src:
    url('fonts/Archia/archia-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/Archia/archia-medium-webfont.woff2') format('woff2'),
    url('fonts/Archia/archia-medium-webfont.woff') format('woff'),
    url('fonts/Archia/archia-medium-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Archia';
  src: url('fonts/Archia/archia-semibold-webfont.eot');
  src:
    url('fonts/Archia/archia-semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/Archia/archia-semibold-webfont.woff2') format('woff2'),
    url('fonts/Archia/archia-semibold-webfont.woff') format('woff'),
    url('fonts/Archia/archia-semibold-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Archia';
  src: url('fonts/Archia/archia-bold-webfont.eot');
  src:
    url('fonts/Archia/archia-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/Archia/archia-bold-webfont.woff2') format('woff2'),
    url('fonts/Archia/archia-bold-webfont.woff') format('woff'),
    url('fonts/Archia/archia-bold-webfont.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

/* Color Pallete */

$blue: #007bff;
$blue-light: #007bff10;
$blue-hover: #007bff30;
$blue-light-opaque: #eff7ff;
$blue-mid: #007bff99;
$pblue: #4c75f2;
$pblue-light: #4c75f210;
$pblue-hover: #4c75f230;
$pblue-light-opaque: #eff7ff;
$pblue-mid: #4c75f299;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$cherry: #ff073a;
$cherry-mid: #ff073a99;
$cherry-light: #ff073a20;
$cherry-hover: #ff073a30;
$cherry-light-opaque: #ffe0e6;
$red: #dc3545;
$red-light: #dc354520;
$red-mid: #dc354599;
$orange: #fd7e14;
$orange-mid: #fd7e1499;
$orange-light: #fd7e1420;
$orange-hover: #fd7e1430;
$orange-light-opaque: #ffefe2;
$yellow: #ffc107;
$yellow-light: #ffc10720;
$yellow-hover: #ffc10730;
$yellow-mid: #ffc10799;
$yellow-light-opaque: #fff7e0;
$green: #28a745;
$green-light: #28a74520;
$green-hover: #28a74530;
$green-mid: #28a74599;
$teal: #20c997;
$cyan: #17a2b8;
$white: #fff;
$gray: #6c757d;
$gray-light: #6c757d10;
$gray-hover: #6c757d20;
$gray-mid: #6c757d99;
$gray-dark: #343a40;
$gray-opaque: #f1f1f1;
$primary: #007bff;
$secondary: #6c757d;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #343a40;
$purple: #201aa2dd;
$purple-light: #201aa220;
$purple-hover: #201aa230;
$purple-mid: #201aa299;
$purple-light-opaque: #e3e2f3;
$pink: #fb5581;
$pink-light: #ffa8cb10;
$pink-hover: #ffa8cb30;
$pink-mid: #ffa8cb99;

@mixin navbarReveal($color) {
  $animation-name: unique-id() !global;

  animation-name: $animation-name;
  animation-duration: 0.75s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;

  @keyframes #{$animation-name} {

    0% {
      background: transparent;
    }

    100% {
      background: $color;
    }
  }
}

@mixin levelColor($color) {
  .is-#{$color} {

    h1 { color: $color; }

    h4,
    h5 { color: #{$color}-mid; }
  }
}

body {
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

h1 {
  font-family: 'archia';
  font-size: 24px !important;
  font-weight: 900;
  text-transform: uppercase;
}

h2 {
  font-family: 'archia';
  font-size: 18px !important;
  font-weight: 400;
  text-transform: uppercase;
}

h3 {
  font-family: 'archia';
  font-size: 14px !important;
  font-weight: 400;
  text-transform: uppercase;
}

h4 {
  font-family: 'archia';
  font-size: 13px !important;
  font-weight: 900;
  text-transform: uppercase;
}

h5 {
  font-family: 'archia';
  font-size: 12px !important;
  font-weight: 900;
  text-transform: uppercase;
}

h6 {
  font-family: 'archia';
  font-size: 11px !important;
  font-weight: 600;
  text-transform: uppercase;
}

.arrow-up {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid $gray-mid;
  margin-left: 0.25rem;
}

.arrow-down {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid $gray-mid;
  margin-left: 0.25rem;
}

.Home {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 5rem;
  margin-right: 5rem;

  .home-left,
  .home-right {
    display: flex;
    flex-direction: column;
    width: 30rem;
  }

  .home-left {
    margin-right: 2.5rem;
    margin-left: 2.5rem;
  }

  .home-right {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
}

.Banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 20px;
  font-family: 'Archia';
  font-size: 0.8rem;
  font-weight: 600;
  color: $purple;
  background: $purple-light;

  .snippet {
    align-self: center;
    width: calc(100% - 10rem);
    word-wrap: break-word;
    padding: 1.5rem;
    text-align: center;
    cursor: pointer;
  }
}

.Navbar {
  display: flex;
  flex-direction: row;
  height: 4.5rem;
  justify-content: space-around;
  text-transform: uppercase;
  font-weight: 900;

  @include navbarReveal($gray-light);

  .navbar-left {
    margin-left: 2rem;
    flex: 1;
    align-self: center;

    a {
      display: inline-block;
      font-size: 14px;
      font-family: 'Archia';
      text-decoration: none;
      margin-right: 2rem;
      color: $gray-mid;

      span {
        display: inline-block;
        transition: color 0.2s ease-in-out;
        
        &:hover {
          color: $gray;
        }
      }
    }
  }

  .focused {
    color: $gray;
  }

  img {
    width: 3rem;
    height: 3rem;
    align-self: center;
    margin-left: 5rem;
    cursor: pointer;
  }
}

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 30rem;
  align-self: center;
  justify-content: space-between;
  padding-top: 2rem;
  margin-bottom: 1rem;

  h1,
  h6 {
    margin: 0;
  }

  h1 {
    color: $gray-dark;
  }

  .header-mid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .titles {
      h6 {
        margin-top: 0.25rem;
        color: $gray;
      }
    }

    & > * {
      align-self: center;
    }
  }
}

.last-update {
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: right;
  margin-bottom: auto;
  margin-top: 0.25rem;

  h6 {
    color: $green-mid;
    font-weight: 900;
  }

  h3 {
    color: $green;
    font-weight: 600;
  }

  h3,
  h6 {
    margin: 0;
  }
}

.button {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  border: 0;
  font-family: 'archia';
  text-transform: uppercase;
  background: $blue-light;
  color: $blue-mid;
  cursor: pointer;
  font-weight: 900;
  border-radius: 5px;
  transition: background 0.2s ease-in-out;
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;
  outline: none;
  font-size: 0.75rem;
  text-decoration: none;

  svg {
    margin-right: 0.5rem;
    width: 20px;
    stroke-width: 2px;
  }

  &.is-purple {
    color: $purple-mid;
    background: $purple-light;

    &:hover {
      background: $purple-hover;
    }
  }

  &.is-green {
    color: $green-mid;
    background: $green-light;

    &:hover {
      background: $green-hover;
    }
  }

  &:hover {
    background: $blue-hover;
  }

  & > * {
    align-self: center;
  }
}

.telegram {
  width: 15.5rem;
  background: $gray-light !important;
  color: #08c !important;
  padding-left: 0.65rem;
  justify-content: center;

  &:hover {
    background: $gray-hover !important;
  }
}

.github {
  width: 15.5rem;
  background: #000 !important;
  color: #fff !important;
  padding-left: 0.65rem;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: $gray-dark !important;
  }
}

.twitter {
  width: 15.5rem;
}

.excel {
  color: #33a667;
  background: #33a66730;

  &:hover {
    background: #33a66750;
  }
}

.Level {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  width: 30rem;

  .level-item {
    display: flex;
    flex-direction: column;

    & > * {
      align-self: center;
    }

    &:first-child {
      margin-left: 0.5rem;
    }

    &:last-child {
      margin-right: 0.5rem;
    }
  }

  h1,
  h5 {
    margin-bottom: 0;
  }

  h1 {
    font-weight: 600;
  }

  h4 {
    margin-bottom: -0.5rem;
  }

  .is-cherry {
    h1 { color: $cherry; }

    h4,
    h5 { color: $cherry-mid; }
  }

  .is-blue {
    h1 { color: $blue; }

    h4,
    h5 { color: $blue-mid; }
  }

  .is-green {
    h1 { color: $green; }

    h4,
    h5 { color: $green-mid; }
  }

  .is-gray {
    h1 { color: $gray; }

    h4,
    h5 { color: $gray-mid; }
  }
}

abbr {
  text-decoration: none;

  &.is-cherry {
    color: $cherry;
  }

  &.is-blue {
    color: $blue;
  }

  &.is-green {
    color: $green;
  }

  &.is-gray {
    color: $gray;
  }
}

.table-fineprint {
  text-align: right;
  margin: 0 0 0 0;
  color: $gray-mid;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
  a {
    text-decoration: none;
    color: inherit;
    border-bottom: 2px solid $gray-hover;
    transition: all 0.2s ease-in-out;
    &:hover {
      background: $gray-hover;
    }
  }
}

.sticky {
  position: sticky;
}

table {
  position: relative;
  width: 30rem;
  align-self: center;
  font-family: 'archia';
  text-transform: uppercase;
  border-spacing: 3px 2px;
  border-collapse: separate;

  thead {
    background: $gray-light;
    color: $gray-dark;
    text-align: left;
    font-size: 0.75rem;

    th {
      padding: 0.5rem;
      cursor: pointer;
      border-radius: 5px;
      transition: all 0.1s ease-in-out;
      z-index: 99;

      &.sticky {
        top: 4px;
        background: $gray-opaque;
      }

      &:hover {
        background: #ecedee;
      }

      .heading-content {
        display: flex;
        justify-content: space-between;
        position: relative;
        flex-direction: row;
        height: 0.9rem;
        align-items: center;

        abbr {
          text-align: right;
        }

        svg {
          width: 10px;
          margin: 0;
          right: 0;
          stroke-width: 4px;
          margin-top: 0.15rem;
          margin-left: 0.25rem;
          color: $gray-mid;
        }
      }
    }
  }

  tbody {
    color: $gray;

    tr {
      transition: background 0.1s ease-in-out;
      cursor: pointer;

      &:hover {
        background: $gray-hover !important;
      }

      &.divider {
        height: 0.5rem;
        background: #6c757d10 !important;
      }

      &.spacer {
        height: 0.5rem;
        background: #fff !important;
      }
    }

    td {
      padding: 0.25rem;
      font-size: 0.9rem;
      border-radius: 5px;
      text-align: right;
      .deltas {
        margin-right: 0.25rem;
        font-size: 11px !important;

        svg {
          width: 9px;
          height: 9px;
          stroke-width: 3;
          vertical-align: -0.25px;
        }
      }
    }

    td:first-child {
      text-align: left;
      max-width: 7.5rem;
      word-wrap: break-word;
    }

    .state-last-update{
      background: transparent;
      transform: translateX(1rem);
      &:hover{
        background: #fff !important;
      }

      td{
        .last-update{
          text-align: left;
          display: flex;
          flex-direction: row;
          align-items: baseline;
          width: 100%;
        }
      }
    }

    .district-heading {
      transform: translateX(1rem);
      background: $gray-light;

      td {
        font-size: 0.75rem;
        font-weight: 900;
        padding: 0.45rem;
        color: $gray-dark;
        background: $gray-light;

        .heading-content {
          display: flex;
          justify-content: space-between;
          position: relative;
          flex-direction: row;
          height: 0.9rem;
          align-items: center;

          abbr {
            text-align: right;
          }

          svg {
            width: 10px;
            margin: 0;
            right: 0;
            stroke-width: 4px;
            margin-left: 0.25rem;
            margin-top: 0.15rem;
            color: $gray-mid;
          }
        }
      }
    }

    max-width: 10rem;

    .district {
      transform: translateX(1rem);
    }
  }

  .affected-count {
    position: absolute;
    margin: 0;
    top: -1rem;
    color: $gray-mid;
    right: 0;
  }

  .is-total {
    td {
      background: $gray-hover;
    }
  }

  .dropdown {
    position: absolute;
    left: -0.75rem;
    background: $gray-light;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    margin-top: 0.5rem;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
      background: $gray-hover;
    }

    svg {
      position: absolute;
      top: -0.35rem;
      left: 1px;
      width: 11px;
      stroke-width: 3;
    }
  }
}

#chart {
  z-index: 10;
}

.back-button {
  z-index: 11;
}

.map-default {
  stroke: none;
}

.map-hover {
  stroke: #ff073a;
  stroke-width: 2;
}

.MapExplorer {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 30rem;

  .header {
    display: flex;
    flex-direction: column;
    color: $gray;
    margin-bottom: 2.5rem;
    align-self: flex-start;
    h1 {
      margin-bottom: 0.25rem;
    }
  }

  .svg-parent {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;

    svg {
      align-self: center;

      text {
        text-transform: uppercase;
        text-align: right;
        font-family: 'archia';
        font-weight: 600;
        fill: $gray;
        font-size: 10px;
      }
    }
  }

  .back-button {
    position: absolute;
    right: 0;
    background: $orange-light;
    color: $orange;
    transition: all 0.2s ease-in-out;
    &:hover {
      background: $orange-hover;
    }
  }

  .meta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 1rem;

    .district-last-update{
      position: absolute;
      right: 0;
      bottom: 0;
    }

    h2 {
      margin: 0;
      color: #e23028;
      word-wrap: break-word;
      font-weight: 900;
    }

    h4 {
      margin: 0;
      color: $gray-mid;
      word-wrap: break-word;
    }

    .unknown {
      position: absolute;
      top: 17rem;
      width: 5rem;
    }
  }

  .map-stats {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 5rem;
    position: relative;

    h1,
    h5,
    h6,
    h3 {
      margin: 0;
    }

    h3 {
      color: $gray-mid;
      font-weight: 900;
    }

    .stats {
      background: $cherry-light;
      color: $cherry;
      padding: 0.5rem;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      margin-bottom: 0.5rem;
      width: 100%;

      .stats-bottom {
        display: flex;
        flex-direction: row;

        h6 {
          margin-top: auto;
          margin-bottom: 0.25rem;
        }
      }

      h1 {
        font-weight: 600;
      }

      &.is-green {
        background: $green-light;
        h5,
        h6 { color: $green-mid; }

        h1 { color: $green; }
      }

      &.is-blue {
        background: $blue-light;
        h5,
        h6 { color: $blue-mid; }

        h1 { color: $blue; }
      }

      &.is-gray {
        background: $gray-light;
        h5,
        h6 { color: $gray-mid; }

        h1 { color: $gray; }
      }

      &.is-yellow {
        background: $warning;
        h5,
        h6 { color: $white; }

        h1 { color: $white; }
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.tabs {
  position: relative;
  z-index: 99;
  display: flex;
  flex-direction: row;

  .tab {
    min-width: 5rem;
    background: $gray-light;
    padding-left: 1rem;
    padding-right: 1rem;
    color: $gray-mid;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
    text-align: center;
    margin-right: 0.25rem;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: $gray-hover;
    }

    &.focused {
      color: $gray;
      background: $gray-opaque;
    }
  }
}

.TimeSeries-Parent {
  display: flex;
  flex-direction: column;
  width: 30rem;
  align-self: center;
}

.timeseries-header {
  h1 {
    color: $gray-dark;
    margin: 0;
    margin-bottom: 0.5rem;
  }

  width: 30rem;
  align-self: center;
  margin-top: 1rem;

  .scale-modes{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    bottom: 0;
    right: 0;
    z-index: 99;
    color: $gray;
    margin-top: 1rem;
    label {
      font-family: 'archia';
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight: 900;
      z-index: 99;
      color: $gray-mid;
    }

    & > * {
      align-self: center;
    }

    .timeseries-mode, .timeseries-logmode {
      margin-left: 1rem;
      display: flex;
      flex-direction: row;
      input.switch {
        position: relative;
        appearance: none;
        outline: none;
        margin: 0;
        cursor: pointer;
        width: 24px;
        height: 14px;
        background-color: #fff;
        border: 2px solid #d9dadc;
        border-radius: 10px;
        transition: all 200ms ease-in-out;
      }
  
      input.switch::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 10px;
        height: 10px;
        background-color: $gray-mid;
        border-radius: 50%;
      }
  
      input.switch:checked {
        background-color: $gray-mid;
        transition: all 200ms ease-in-out;
      }
  
      input.switch:checked::after {
        left: 10px;
        background-color: $light;
      }
      
      label{
        font-size: 0.75rem;
        margin-right: 0.2rem;
        padding-bottom: 0;
        color: $gray;
      }
    }

    .disabled {
      input.switch {
        background: $gray-light;
        border: $gray-light 2px solid;
        cursor: not-allowed;
      }

      input.switch::after {
        background: $gray-light;
      }
    }
  }
}

.timeseries,
.Minigraph {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;

  .stats {
    padding: 0.25rem;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    width: 5rem;
    border-radius: 3px;
    display: flex;
    flex-direction: column;

    h2,
    h5,
    h6 {
      margin: 0;
      color: $cherry-mid;
    }

    h2,
    h6 {
      color: $cherry;
    }

    .stats-bottom {
      display: flex;
      flex-direction: row;

      h6 {
        margin-bottom: 0.1rem;
        margin-left: 0.25rem;
      }

      & > * {
        margin-top: auto;
      }
    }

    &.is-green {
      h5 { color: $green-mid; }

      h2,
      h6 { color: $green; }
    }

    &.is-gray {
      h5 { color: $gray-mid; }

      h2,
      h6 { color: $gray; }
    }

    &.is-blue {
      h5 { color: $blue-mid; }

      h2,
      h6 { color: $blue; }
    }
  }

  .svg-parent {
    position: relative;
    display: flex;
    width: 30rem;
    align-self: center;
    background: $cherry-light;
    border-radius: 5px;
    margin-bottom: 1rem;
    height: 10rem;

    svg {
      .domain,
      .tick,
      line {
        stroke: $cherry;
        stroke-width: 2;
      }

      text {
        font-family: 'archia';
        text-transform: uppercase;
        color: $cherry-mid;
        stroke: transparent;
        font-size: 12px;
      }
    }

    &.is-green {
      background: $green-light;

      svg {
        .domain,
        .tick,
        line {
          stroke: $green;
        }

        text {
          color: $green-mid;
          stroke: transparent;
        }
      }
    }

    &.is-gray {
      background: $gray-light;

      .domain,
      .tick,
      line {
        stroke: $gray;
      }

      text {
        color: $gray-mid;
        stroke: transparent;
      }
    }
  }
}

.Minigraph {
  width: 30rem;
  align-self: center;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .svg-parent {
    width: 5rem;
    background: transparent !important;
    height: 7rem;
  }

  margin-bottom: -3rem;

  .tooltip {
    position: fixed;
    top: 0;
    right: 0;
  }
}

.Links {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.link {
  width: 30rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-self: center;

  a {
    font-family: 'archia';
    text-transform: uppercase;
    word-wrap: break-word;
    font-weight: 900;
    text-decoration: none;
    color: $blue;
    background: $blue-light;
    transition: background 0.2s ease-in-out;

    &:hover {
      background: $blue-hover;
    }
  }

  h3 {
    margin-bottom: 0;
    color: $gray-dark;
    font-weight: 900;
  }
}

footer {
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
  justify-content: center;
  margin-top: 5rem;

  & > * {
    align-self: center;
    text-align: center;
  }

  h5 { margin: 0; margin-top: 1rem; margin-bottom: 0.5rem; color: $gray; }

  img {
    width: 2rem;
    height: 2rem;
  }
}

.Summary {
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 600px;
  position: relative;
  padding-left: 1rem;
  padding-right: 1rem;

  img {
    width: 2rem;
    height: 2rem;
  }

  .link {
    margin: 0;
  }

  .header {
    h1 {
      margin-top: -1rem;
    }
  }

  h5 {
    margin: 0;
    color: $gray;
  }

  .Minigraph {
    align-self: center;
    margin-bottom: -3.25rem;
  }

  .summary-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    width: calc(100% - 0);

    .link {
      width: 12rem;
      margin-right: -0.75rem;
    }

    & > * {
      align-self: center;
    }

    img {
      margin-bottom: 0.25rem;
      margin-right: 0.25rem;
      margin-left: 1rem;
    }

    h5 {
      width: 15rem;
    }
  }

  .summary-bottom-left {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    & > * {
      align-self: center;
    }
  }
}

.FAQ {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 30rem;

  & > * {
    align-self: center;
    width: 30rem;
    margin-bottom: 1.5rem;
  }

  & > *:first-child {
    margin-top: 2rem;
  }

  h2 {
    margin: 0;
    font-weight: 600;
    text-transform: none;
  }

  .question {
    color: $gray-dark;
    margin: 0;
    margin-bottom: 0.25rem;
  }

  .answer {
    color: $blue;
    line-height: 1.5rem;
  }

  a {
    background: $blue-light;
    color: $blue;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.PatientsDB {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-left: auto;
  margin-right: auto;

  .header {
    padding-left: 5.25rem;
    margin-top: 1.75rem;
    align-self: flex-start;
    h1 {
      color: $pblue;
    }

    h3 {
      margin-top: 0;
      color: $pblue-mid;
      font-weight: 900;
    }
  }

  .patientdb-wrapper {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.Patients {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  align-self: center;
  min-height: 20rem;

  & > * {
    align-self: center;
  }

  h5.daylabel {
    color: $pblue;
  }

  .day {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .patient-card {
    width: 3.25rem;
    height: 3.25rem;
    background: $gray-light;
    margin: 0.25rem;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    & > * {
      align-self: center;
    }

    &:hover {
      background: $gray-hover;
    }

    h3 {
      font-weight: 900;
      color: $gray-mid;
      font-size: 11px !important;
    }

    &.is-femme {
      background: $pink-mid;
      h3 {
        color: $pink;
      }

      &:hover {
        background: $pink-hover;
      }
    }


    &.is-male {
      background: $pblue-light;
      h3 {
        color: $pblue;
      }

      &:hover {
        background: $pblue-hover;
      }
    }

    &.is-local {
      background: $cherry-light;
      h3 {
        color: $cherry;
      }

      &:hover {
        background: $cherry-hover;
      }
    }

    &.is-imported {
      background: $purple-light;
      h3 {
        color: $purple;
      }

      &:hover {
        background: $purple-hover;
      }
    }

    &.is-in {
      background: url('/flags/india.png');
      background-size: cover !important;
      h3 {
        color: transparent;
      }

      &:hover {
        background: $gray-light;
        h3 {
          color: $gray-mid;
        }
      }
    }

    &.is-uk {
      background: url('/flags/uk.png');
      background-size: cover !important;
      h3 {
        color: transparent;
      }

      &:hover {
        background: $gray-light;
        h3 {
          color: $gray-mid;
        }
      }
    }

    &.is-us {
      background: url('/flags/usa.png');
      background-size: cover !important;
      h3 {
        color: transparent;
      }

      &:hover {
        background: $gray-light;
        h3 {
          color: $gray-mid;
        }
      }
    }

    &.is-th {
      background: url('/flags/thailand.png');
      background-size: cover !important;
      h3 {
        color: transparent;
      }

      &:hover {
        background: $gray-light;
        h3 {
          color: $gray-mid;
        }
      }
    }

    &.is-ph {
      background: url('/flags/philippines.png');
      background-size: cover !important;
      h3 {
        color: transparent;
      }

      &:hover {
        background: $gray-light;
        h3 {
          color: $gray-mid;
        }
      }
    }

    &.is-it {
      background: url('/flags/italy.png');
      background-size: cover !important;
      h3 {
        color: transparent;
      }

      &:hover {
        background: $gray-light;
        h3 {
          color: $gray-mid;
        }
      }
    }

    &.is-ca {
      background: url('/flags/canada.png');
      background-size: cover !important;
      h3 {
        color: transparent;
      }

      &:hover {
        background: $gray-light;
        h3 {
          color: $gray-mid;
        }
      }
    }

    &.is-id {
      background: url('/flags/indonesia.png');
      background-size: cover !important;
      h3 {
        color: transparent;
      }

      &:hover {
        background: $gray-light;
        h3 {
          color: $gray-mid;
        }
      }
    }

    &.is-mm {
      background: url('/flags/myanmar.png');
      background-size: cover !important;
      h3 {
        color: transparent;
      }

      &:hover {
        background: $gray-light;
        h3 {
          color: $gray-mid;
        }
      }
    }
  }

  /*
  .patient-card {
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    width: 13rem;
    background: $pblue-light;
    margin: 0.25rem;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    justify-content: space-between;
    h1, h2, h3, h4, h5, h6 {margin: 0;}

    .patient-card-left {
      display: flex;
      flex-direction: column;
      .patient-card-left-top {
        h2 {margin-bottom: 0.25rem; font-weight: 900;}

        h2, h3 {
          color: $pblue;
          font-weight: 600;
        }

        h6 {
          color: $pblue-mid;
          font-weight: 900;
        }
      }

      .patient-card-left-bottom {
        margin-top: 0.25rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        & > .patient-card-snippet {
          display: flex;
          flex-direction: column;
          h6 {
            color: $pblue-mid;
            font-weight: 900;
          }

          h4 {
            color: $pblue;
          }
        }
      }
    }

    .patient-card-right {
      text-align: right;
      margin-left: 0.5rem;
      .patient-card-right-top {
        display: flex;
        flex-direction: column;
        & > * {
          align-self: flex-end; 
        }

        h5 {
          color: $pblue;
          margin-bottom: 0.25rem;
          word-wrap: break-word;
          width: 7rem;
        }

        h6 {
          color: $pblue-mid;
          font-weight: 900;
        }
      }
    }

    &:hover {
      background: $pblue-hover;
    }
  }*/
}

.DownloadBlock {
  margin-top: 2rem;
  align-self: center;
    code {
      font-family: 'archia';
      color: $orange-mid;
    }

    a {
     text-decoration: none !important;
     color: $orange-mid;
    }

    .button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      & > * {
        align-self: center;
      }
      background: $orange-light;
      height: 3rem;
      width: 10rem;
      text-align: center;
      &:hover {
        background: $orange-hover;
      }
    }

    svg {
      stroke: $orange-mid;
      stroke-width: 3;
    }
}

.modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: $pblue-mid;
  z-index: 9999;
  .modal-content {
    position: fixed;
    padding: 1rem;
    border-radius: 5px;
    align-self: center;
    background: #fff;
    width: 30rem;
    height: 40rem;
    overflow-y: auto;
    svg {
      width: 20px;
      stroke: $pblue-mid;
      stroke-width: 3;
      transition: all 0.1s ease-in-out;
      &:hover {
        stroke: $pblue;
      }
    }

    .close-button {
      cursor: pointer;
      position: absolute;
      right: 1rem;
    }

    h1 {
      color: $pblue;
    }

    h5, h3 {
      margin: 0;
      font-weight: 900;
    }

    h3 {
      margin-bottom: 0.5rem;
      color: $pblue;
    }

    h5 {
      color: $pblue-mid;
    }

    .meta {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 10rem;
      & > * {
        width: 10rem;
      }

      h3.contracted-from {
        text-decoration: underline;
        text-decoration-style: dotted;
        cursor: pointer;
        transition: all 0.1s ease-in-out;
        width: 2rem;
        &:hover {
          background: $pblue-light;
        }
      }
    }

    .modal-top {
      display: flex;
      flex-direction: row;
      & > {
        align-self: center;
      }
    }

    .notes {
      width: 10rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .link {
      margin: 0;
      margin-top: 0.25rem;
      margin-bottom: 0.5rem;
      a {
        color: $pblue;
        background: $pblue-light;
        transition: background 0.2s ease-in-out;
        &:hover {
          background: $pblue-hover;
        }
      }
    }
  }
}

.patients-summary {
  width: 30rem;
  align-self: center;
  .Patients {
    min-height: 5rem;
  }

  h1 {
    color: $gray-dark;
    margin-bottom: 0.25rem;
  }

  h6 {
    margin: 0;
    margin-top: 0;
    color: $gray;
    font-weight: 600;
  }

  .summary { 
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse
  }

  .patients-summary-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    & > * {
      align-self: center;
    }
  }

  .button {
    background: $pblue-light;
    height: 3rem;
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    color: $pblue;
    & > * {
      align-self: center;
    }

    &:hover {
      background: $pblue-hover;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    flex-direction: row;
    display: flex;
    & > * {
      align-self: center;
    }
  }

  .daylabel {
    align-self: flex-start;
  }

  .link {
    a {
      display: inline !important;
      padding-bottom: 0;
    }
  }

  .legend {
    padding: 1rem;
    justify-content: flex-start;
    padding-left: 0;
    padding-bottom: 0.25rem;
  }
}

.select {
  display: flex;
  flex-direction: column;
  label {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 900;
    color: $gray-mid;
    margin-bottom: 0.25rem;
  }
}

select {
  width: 7rem;
  margin-right: 0.25rem;
  cursor: pointer;
}

.filters {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 1rem;
  background: #fff !important;
  z-index: 101;
  font-family: archia;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,.12),inset 0 -1px 0 0 #dadce0;
  justify-content: space-between;

  & > * {
    align-self: center;
  }

  .filters-left {
    display: flex;
    flex-direction: row;
  }
}

.legend {
  background: #fff;
  z-index: 101;
  font-family: archia;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > * {
    align-self: center;
  }

  .legend-left {
    display: flex;
    flex-direction: row;

    &.nationality {
      margin-bottom: 0.5rem;
    }
  }

  select {
    margin-left: 1rem;
  }

  h5 {
    margin: 0;
    margin-right: 0.5rem;
    color: $gray;
    &.is-female {
      color: $pink;
    }

    &.is-male {
      color: $pblue;
    }

    &.is-local {
      color: $cherry;
    }

    &.is-imported {
      color: $purple;
    }
  }

  .circle {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: $gray-mid;
    margin-right: 0.25rem;
    &.is-female {
      background: $pink;
    }

    &.is-male {
      background: $pblue;
    }

    &.is-local {
      background: $cherry;
    }

    &.is-imported {
      background: $purple;
    }

    &.is-in {
      background: url('/flags/india.png');
      background-size: cover !important;
    }

    &.is-uk {
      background: url('/flags/uk.png');
      background-size: cover !important;
    }

    &.is-us {
      background: url('/flags/usa.png');
      background-size: cover !important;
    }

    &.is-th {
      background: url('/flags/thailand.png');
      background-size: cover !important;
    }

    &.is-ph {
      background: url('/flags/philippines.png');
      background-size: cover !important;
    }

    &.is-it {
      background: url('/flags/italy.png');
    background-size: cover !important;
    }

    &.is-ca {
      background: url('/flags/canada.png');
      background-size: cover !important;
    }

    &.is-id {
      background: url('/flags/indonesia.png');
      background-size: cover !important;
    }

    &.is-mm {
      background: url('/flags/myanmar.png');
      background-size: cover !important;
    }
  }
}

@media (max-width: 769px) {
  .TimeSeries,
  table,
  .header,
  .Level,
  .timeseries-header,
  .TimeSeries-Parent,
  .Links,
  .Minigraph,
  .Summary,
  .FAQ,
  .MapExplorer,
  .patients-summary
  .filters {
    width: calc(100% - 2rem);
  }

  .Home {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    margin-right: 0;

    .home-left,
    .home-right {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0;
    }
  }

  .link,
  .faq {
    width: calc(100% - 2rem);
  }

  .Navbar {
    a {
      font-size: 0.75rem !important;
      margin-right: 0.75rem !important;
    }

    img {
      width: 2rem;
      height: 2rem;
      margin-left: 1rem;
      margin-right: -1rem;
      cursor: pointer;
    }
  }

  .timeseries {
    .svg-parent {
      width: 100%;
    }
  }

  .last-update {
    width: 10rem;
  }

  a.button {
    display: flex;
    flex: row;
    width: 10rem;
    justify-content: center;

    & > * {
      align-self: center;
    }

    svg {
      width: 38px;
    }
  }

  .Banner {
    height: 7rem;

    .snippet {
      width: 100%;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  table {
    td {
      max-width: 6.75rem;
    }
  }

  .PatientsDB {
    width: 100%;

    .header {
      width: calc(100% - 2rem);
      align-self: center;
      padding-left: 2rem;
    }

    .Patients {
      width: calc(100% - 2rem);
    }

    .modal {
      .modal-content {
        width: calc(100% - 5rem);
        .meta {
          height: 20rem;
        }
      }
    }

    .filters-left {
      flex-wrap: wrap;
      justify-content: space-between;
      select {
        margin-bottom: 1rem;
      }
    }

    .filters {
      height: 7rem;
      justify-content: center;
    }
  }

  .table-fineprint {
    margin-right: 1.25rem;
  }

  .patients-summary {
    width: calc(100% - 2rem);
    .modal {
      .modal-content {
        width: calc(100% - 5rem);
        .meta {
          height: 20rem;
        }
      }
    }
  }

  .patientdb-wrapper {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    .Patients {
      width: 100%;
    }

    .day {
      justify-content: center;
      width: 100%;
    }
  }
}

.iframe-container {
  width: 100%;
  min-height: 100vh;

  iframe {
    width: 100vw;
    height: 100vh;
    border: 0;
  }
}


/* Animation Support */

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 0.45s;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.rotateDownRight {
  animation-name: rotateDownRight;
  animation-duration: 0.25s;
  animation-fill-mode: both;
}

@keyframes rotateDownRight {
  100% {
    transform: rotate(-90deg);
  }
}

.rotateRightDown {
  animation-name: rotateRightDown;
  animation-duration: 0.25s;
  animation-fill-mode: both;
}

@keyframes rotateRightDown {
  0% {
    transform: rotate(-90deg);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
  animation-duration: 0.45s;
  animation-fill-mode: both;
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}
